a {
    text-decoration: none;
    color: black;
}

.gradiant {
    background-image: linear-gradient(to right, #76548B, #A9739E);
    color: white;
    border-radius: 0px 100px 100px 0px;
    cursor: pointer;
    max-width: 100%;
}

.gradiant-background {
    background-image: linear-gradient(to bottom right, #F1CBD4, #FBF2F4, #FAF4F6);
}

.mobile-gradiant {
    background-image: linear-gradient(to right, #A9739E, #76548B);
    color: white;
    border-radius: 0px 100px 100px 0px;
    cursor: pointer;
    max-width: 90%;
}

.appbar-user-img {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    flex-shrink: 0;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: 0.5rem;
}

.appbar-user-details {
    display: inline-flex;
    margin-left: 0.75rem;
    align-items: flex-start;
    flex-direction: column;
}

.appbar-container {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    padding: 10px;
    padding-top: 0px;
}

.appbar-user-name {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: rgba(58, 53, 65, 0.87);
    font-weight: 600;
    margin: 0%;
}

.appbar-user-role {
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.15px;
    color: #0c0c0c78;
    font-size: 0.8rem;
    margin: 0%;
}

.menulanding ::-webkit-scrollbar {
    width: 5px;
}

.undocked-button {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: visible;
    color: #000;
    font-weight: 600;
    background-image: linear-gradient(to left, #facf39, #fbdb6b) !important;
    padding: 5px;
    bottom: 150px;
    position: fixed;
    left: 50px;
    z-index: 1101;
    border: 1px dashed rgba(145, 158, 171, 0.2);
}

.docked-button {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    margin: 0px;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    text-align: center;
    flex: 0 0 auto;
    border-radius: 50%;
    overflow: visible;
    color: #000;
    font-weight: 600;
    background-image: linear-gradient(to left, #facf39, #fbdb6b) !important;
    padding: 5px;
    bottom: 150px;
    position: fixed;
    left: 245px;
    z-index: 1101;
    border: 1px dashed rgba(145, 158, 171, 0.2);
}
.menu_heading{
    margin: 0px 0px 0px 200px;
}
@media screen and (max-width: 767px) {
    .menu_heading{
        margin: 10px 0px 0px 31px;
    }
    .menu_heading h4    {
        font-size: 14px;
    }
    .add_job{
        font-size: 14px;
    }
    .bar_section{
        width: 236px;
    }
}

@media screen and (max-width: 414px) {
   
    .menu_heading{
        margin: 10px 0px 0px 31px;
    }
    .menu_heading h4    {
        font-size: 12px;
    }
    .add_job{
        font-size: 12px;
    }
    .bar_section{
        width: 220px;
    }
   
}

@media screen and (max-width: 376px) {
    .menu_heading{
        margin: 10px 0px 0px 31px;
    }
    .menu_heading h4    {
        font-size: 12px;
    }
    .add_job{
        font-size: 12px;
    }
    .bar_section{
        width: 200px;
    }
}
