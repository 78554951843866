body {
  background-color: white;
}

.css-19r6kue-MuiContainer-root {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.css-1s020o9-MuiDrawer-docked {
  width: 65px !important;
}

.mobileview .css-12irybu-MuiDrawer-docked .MuiDrawer-paper {
  width: calc(35px + 1px) !important;
}

.css-1mbunh-MuiPaper-root {
  box-shadow: none !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 9px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px #b3b3b3;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b3b3b3 !important;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3 !important;
}

.mpl-primary-btn {
  color: #883bd5;
  border: 1px solid #883bd5;
  background-color: transparent !important;
}


.mpl-secondary-btn {
  border-color: linear-gradient(to left, #e14eca, #ba54f5) !important;
  border: 1px solid rgba(139, 68, 255, 0.5) !important;
  color: #8b44ff;
  background-color: #fff !important;
  font-weight: 600 !important;
}

.mpl-secondary-btn:hover {
  color: #8b44ff;
}

.Job_title:hover {
  cursor: pointer;
  color: #8b44ff;
  transition: color 0.35s ease;
  -webkit-transition: color 0.35s ease;
}

.Card-Job:hover {
  cursor: pointer;
}

.Sub-header-job {
  color: #000;
  font-weight: 600;
}

.job-type {
  width: fit-content;
  background-color: #e1e7ff;
  border-radius: 4px;
  padding: 2px 10px;
  font-weight: 400;
}

.css-11h2pvr-MuiButtonBase-root-MuiButton-root:hover {
  color: #8b44ff;
}

.diamond-narrow {
  position: relative;
  height: 11px;
  width: 11px;
  line-height: 200px;
  text-align: center;
  margin: 2px 10px;
  background-color: #e14eca;
  transform: rotateX(360deg) rotateZ(45deg);
  border-radius: 50%;
}

.blink {
  animation: blinker 2s linear infinite;
  color: rgb(248, 163, 4);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.admin-setting .css-hyum1k-MuiToolbar-root {
  min-height: 50px !important;
  max-height: 55px !important;
}

/* Commented for Groupby design */
/* .css-hyum1k-MuiToolbar-root {
    padding-left: 10px !important;
} */
.css-yk4g90-MuiTabs-root .MuiTab-root {
  padding-left: 16px !important;
}

/* MUI Table row */
.MuiTableRow-root:hover {
  background-color: rgb(254 255 187 / 48%) !important;
  z-index: 2;
  box-shadow: rgb(252, 255, 86) 0px 4px 8px -4px;
  transform: translateY(-2px);
}
.MuiTablePagination-toolbar p{
  margin-bottom: 0px !important;
}
/* .MuiTableRow-root {
  border: hidden !important;
} */

.MuiTableHead-root .MuiTableCell-head.MuiTableCell-paddingNone {
  padding: 0px !important;
}

.MuiGrid-item .MuiPaper-root {
  background-color: #e6d9fb7a !important;
}

.MuiContainer-root .MuiPaper-root .MuiToolbar-root {
  background-color: #e6d9fb7a !important;
}

.MuiContainer-root .MuiBox-root .MuiPaper-root .MuiToolbar-root {
  background-color: #e6d9fb7a !important;
}

.MuiTable-root {
  font-family: Nunito Sans;
  background-color: #e6d9fb7a !important;
}

.css-7ugvlq-MuiPaper-root {
  background-color: #e6d9fb7a !important;
}

.makeStyles-DroppableStyles-13 {
  background: #e6d9fb7a !important;
}

.css-16skhjp-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  color: #000000 !important;
}

.css-tbmt5m-MuiTypography-root {
  color: #000000 !important;
}

.css-f2yulb-MuiButtonBase-root-MuiFab-root {
  background-color: #facf39;
}

.css-vb6n0k-MuiButtonBase-root-MuiButton-root {
  background-color: #facf39;
}

.Dash_Picker .MuiFormControl-root .MuiOutlinedInput-root {
  margin-left: 5px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#nprogress .bar {
  background: #8b44ff !important;
  height: 3px !important;
  z-index: 9999 !important;
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px #ce87cb, 0 0 5px #740a70 !important;
}

/* ::-webkit-scrollbar {
    display: none;
} */

.truncate {
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* SIGN AND SIGNUP STYLES*/
.head-sign-sec-h5 {
  font-size: 30px !important;
  font-weight: 600 !important;
}

.head-sign-sec-h3 {
  font-size: 30px !important;
}

.rember-sec span {
  font-family: Clashgrotesk, sans-serif !important;
}

.forget-sec {
  font-family: Clashgrotesk, sans-serif !important;
}

.button-sign-sec {
  padding: 18px 45px !important;
  border-radius: 158px !important;
  background-color: #fecb03 !important;
  transition: color 400ms ease, background-color 500ms ease !important;
  color: #000 !important;
  font-size: 19px !important;
  font-weight: 500 !important;
  text-transform: none !important;
}

.login-box {
  background-color: #facf39 !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  padding: 0 50px !important;
  height: 100% !important;
  text-align: center !important;
}

.main-card {
  background-color: #facf39 !important;
  border-radius: 10px !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
  position: relative !important;
  overflow: hidden !important;
  width: 80% !important;
  height: 90% !important;
}

.popup-sec {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: black !important;
  width: 40% !important;
  border-radius: 20px !important;
  display: flex !important;
  flex-direction: column !important;
  padding: 10px !important;
}

.password-page {
  width: 50% !important;
  margin: auto !important;
  background-color: #facf39 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  padding: 0 50px !important;
  height: 100% !important;
  text-align: center !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  /* -webkit-text-fill-color: #fff !important; */
}

@media screen and (max-width: 899px) {
  .job-type-main {
    padding-top: 10px;
    padding-left: 0px;
  }

  .sub-content-values {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 767px) {
  .head-sign-sec-h5 {
    font-size: 22px !important;
    font-weight: 600 !important;
  }

  .head-sign-sec-h3 {
    font-size: 20px !important;
  }

  .button-sign-sec {
    padding: 10px 27px !important;
    border-radius: 158px !important;
    background-color: #fecb03 !important;
    transition: color 400ms, background-color 500ms !important;
    color: #000 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }

  .login-box {
    padding: 0 10px !important;
  }

  .main-card {
    width: 100% !important;
  }

  .popup-sec {
    width: 80% !important;
    padding: 10px !important;
    font-size: 10px !important;
  }

  .password-page {
    width: 100% !important;
  }
}